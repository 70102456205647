<template>
  <div class="grants col">
    <section class="one row-b_">
      <div class="left">
        <h1 class="title animated fadeInUp">Grants</h1>
        <p class="content animated fadeInUp">
          In support of our mission, the Web3 Foundation grants program funds software development and research in the field of decentralized software protocols. 
        </p>
      </div>
      <div class="right animated fadeInUp">
        <h3>Philosophy</h3>
        <p>Our grants program's aim is to steward the Web 3.0 ecosystem and carefully manage Web3 Foundation resources. We provide grants that we believe will return the highest impact on the Web 3.0 ecosystem over the long term. </p>
        <p>Web3 Foundation awards grants for specific projects and pieces of development work. Grant-winning teams should not be considered partners of Polkadot or Web3 Foundation. </p>
      </div>
    </section>
    <section class="two row_">
      <div class="left">
        <div class="title">Grants recipients</div>
        <div class="text">
          Development projects funded through our grants program are broad in scope and include low-level infrastructure development such as alternative client implementations; ecosystem components such as wallets; and software designed to enable direct use cases such as identity and supply-chain modules.
        </div>
        <a href="https://github.com/w3f/General-Grants-Program/blob/master/grants/accepted_grant_applications.md" class="go row">
          <span>See all recipients</span>
          <span>→</span>
        </a>
      </div>
      <div class="right">
        <span class="i1">100+</span>
        <span class="i2">20+</span>
      </div>
    </section>
    <section class="three row-b_">
      <div class="left">
        <div class="top">
          <div class="title">General Grants Program</div>
          <div class="text">
            This is our standard program that offers funding of up to $100k for technical grants. Funding can be disbursed in fiat or dots. See our Grant <a class="git">Github repository</a> for more information. 
          </div>
          <div class="apply">Apply</div>
        </div>
        <div class="down">
          <div class="title">General Grants Program</div>
          <div class="text">
            This is our standard program that offers funding of up to $100k for technical grants. Funding can be disbursed in fiat or dots. See our Grant <a class="git">Github repository</a> for more information. 
          </div>
          <a class="apply">Apply</a>
        </div>
      </div>
      <div class="right">
        <div class="item" v-for="item of 3" :key='item'>
          <div class="title">Software Development</div>
          <div class="text">Web3 Foundation funds development work driving advancement and adoption of decentralized software protocols. We support projects that make it easier for developers to build useful applications using these protocols.</div>
          <div class="bottom">We are particularly interested in funding projects that build out layers of the <a href="https://github.com/w3f/General-Grants-Program/blob/master/grants/polkadot_stack.md#battery-funding">Polkadot Stack</a>.</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'grants',
}
</script>
<style lang="less" scoped>
* {color: #fff;}
@media screen and (max-width: 940px) { // 移动设备
  .one {
    >.left {
      >.title {font-size: 8vw !important;margin-bottom: 3vh !important;}
      >.content {font-size: 8vw !important;}
    }
    >.right {
      width: 100% !important;
      padding: 1.5vh 4vw !important;
      margin-top: 3.5vh !important;
      >h3 {
        font-size: 5vw !important;
        margin-bottom: 1vh !important;
      }
      margin-bottom: 0 !important;
      >p {
        margin: 0 !important;
        margin-top: 2vh !important;
      }
    }
  }
  .two {
    margin: 0 !important;
    margin-bottom: 15vh !important;
    flex-direction: column !important;
    >div {min-width: 100% !important;}
    >.left {
      padding: 0 !important;
      >.title {font-size: 7vw !important;}
      >.text {margin: 2vh 0 !important;}
    }
    >.right {
      font-size: 28vw !important;
      padding: 0 !important;
      >span {display: block;}
      >span+span {top: 5vh;}
      >span::before {
        font-size: 7vw !important;
        bottom: -3vh !important;
      }
    }
  }
  .three {
    padding: 3vh 0 !important;
    display: block !important;
    >div {min-width: 100% !important;}
    >.left {
      padding: 0 2vh;
      box-sizing: border-box;
      margin-bottom: 3vh;
      .title {font-size: 7.5vw !important;}
      .apply {padding: 1vh 3vw !important;}
    }
    >.right {
      padding: 0 3vw !important;
      box-sizing: border-box;
      >.item+.item {margin-top: 2.5vh !important;}
      >.item {
        padding: 2vh 4vw !important;
        >.title {font-size: 6vw !important;}
        >.text {
          font-size: 6vw !important;
          margin: 2vh 0 !important;
        }
      }
    }
  }
}
  .grants {
    width: 100%;
    >section {width: 100%;}
    >.one {
      margin: 13vh 0 4vh;
    //   background: #eee;
      >.left {
        >h1 {margin-bottom: 7.5vh;font-size: 2em;}
        >p {
          line-height: 1.2;
          max-width: 740px;
          font-size: 2.1rem;
        }
      }
      >.right {
        background: #000;
        box-sizing: border-box;
        width: 32%;
        max-width: 350px;
        padding: 40px;
        line-height: 1.4;
        color: #fff;
        >h3 {margin-bottom: 6vh;}
        >p {margin-bottom: 5vh;font-size: 14px;}
        >p+p {margin-bottom: 0;}
      }
    }
    >.two {
      margin-top: 5vh;
      margin-bottom: 10vh;
      >.left {
        max-width: 30%;
        // background: #eee;
        padding: 3vh 0;
        >.title {
          font-size: 1.3vw;
          font-weight: 700;
        }
        >.text {
          line-height: 1.5;
          font-size: 1.05em;
          margin: 6vh 0;
        }
        >a {
          font-size: .9em;
          span+span {
            font-size: 1.5em;
            padding-left: .5vw;
            transition: all ease .3s;
          }
        }
        >a:hover >span+span {padding-left: 1vw;}
      }
      >.right {
        font-size: 8vw;
        font-weight: 900;
        padding-left: 5vw;
        >span {
          margin: 0 1.5vw;
          position: relative;
        }
        >span::before {
          content: '123';
          font-size: 1.5vw;
          font-weight: 400;
          position: absolute;
          bottom: -4vh;
        }
        >.i1::before{content: 'projects funded';}
        >.i2::before{content: 'in countries';}
      }
    }
    >.three {
      padding: 12vh 0;
      background: #EDEDED;
      padding-left: 2vw;
      .left {
        max-width: 25%;
        >div {width: 100%;}
        >div+div {
          margin-top: 5vh;
        }
        .title {font-size: 1.7vw;}
        .text {
          font-size: .9em;
          margin: 3vh 0;
        }
        .git {color: #B3B3B3;}
        .apply {
          font-size: .9em;
          display: inline-block;
          padding: 2.5vh 1.5vw;
          background: #fff;
        }
      }
      >.right {
        max-width: 65%;
        padding-right: 2vw;
        box-sizing: border-box;
        >.item+.item {margin-top: 12vh;}
        >.item {
          padding: 40px;
          background: #fff;
          box-shadow: 0 0 1.5vw #ccc;
          >.title {
            font-size: 1.3vw;
            font-weight: 700;
          }
          >.text {
            font-size: 1.3vw;
            line-height: 1.4;
            margin: 5vh 0;
          }
          >.bottom {
            font-size: .9em;
            a {
              font-weight: 700;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
</style>
